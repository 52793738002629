/* Ensure wrapper is full height and has flexible layout */
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
}

/* Align the buttons and dropdown in one row */
.button-container {
    display: flex;
    justify-content: space-between;  /* Distribute buttons on each side */
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease-out;  /* Faster fade-out */
}

/* Fade-out effect when loading */
.button-container.fade-out {
    opacity: 0; /* Hide when loading */
}

/* Fade-in effect when loading completes */
.button-container.fade-in {
    opacity: 1; /* Show when data is ready */
}

/* Style for "Loading" message */
.loading-container {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
    color: #888;
    opacity: 0;
    transition: opacity 0.3s ease-in-out; /* Faster fade-in */
}

/* Show loading message smoothly */
.loading-container.fade-in {
    opacity: 1;
}

/* Style for table header */
.css-mlny8k.MuiTableCell-head {
    background-color: rgb(68, 114, 196);
    color: rgb(255, 255, 255);
    font-weight: bold;
}

/* Style for table data */
.css-mlny8k {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-align: center;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid rgb(224, 224, 224);
}

/* Table container style */
.table-container {
    width: 100%;
    margin-top: 20px;
}

/* Align the dropdown to the right and the button to the left */
.quarter-dropdown {
    width: 200px;
    align-self: flex-end;  /* Align dropdown to the right */
}

.back-to-form-button {
    background-color: rgb(46, 125, 50);
    border-radius: 5px;
    color: white;
    font-size: 12px;
    padding: 10px 10px;
    height: 35px;
    align-self: flex-start;  /* Align button to the left */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .wrapper {
        padding: 10px;
    }
    .button-container {
        flex-direction: column;
        align-items: stretch;
    }
}